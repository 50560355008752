import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Accordion } from 'hds-react';
import ExternalLink from '../../../components/ExternalLink';
import TabsLayout from './tabs.mdx';
export const _frontmatter = {
  "slug": "/getting-started/faq/code",
  "title": "Frequently asked questions"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = ({
  children,
  pageContext
}) => <TabsLayout pageContext={pageContext}>{children}</TabsLayout>;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2 {...{
      "id": "code",
      "style": {
        "position": "relative"
      }
    }}>{`Code`}<a parentName="h2" {...{
        "href": "#code",
        "aria-label": "code permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <Accordion heading="How can I get the Helsinki Grotesk font in .hel.fi domain services?" headingLevel="3" theme={{
      '--header-font-size': 'var(--fontsize-heading-xs)',
      '--padding-vertical': 'var(--spacing-s)'
    }} mdxType="Accordion">
  Please refer to <ExternalLink href="https://helsinkisolutionoffice.atlassian.net/wiki/spaces/DD/pages/296058881/Using+Helsinki+Grotesk+fonts" mdxType="ExternalLink">the internal font documentation in Confluence</ExternalLink>. If you do not have access to Confluence, ask your project’s city employee contact.
    </Accordion>
    <Accordion heading="Where to get help with Drupal and WordPress?" headingLevel="3" theme={{
      '--header-font-size': 'var(--fontsize-heading-xs)',
      '--padding-vertical': 'var(--spacing-s)'
    }} mdxType="Accordion">
  While HDS does not currently offer WordPress or Drupal implementations, HDS cooperates with multiple WordPress and Drupal projects in the City of Helsinki. Many of these projects have already implemented HDS components which can be reused in other projects.
      <p>{`  If your project is using either WordPress or Drupal, please contact `}<a parentName="p" {...{
          "href": "mailto:ketu@hel.fi"
        }}>{`ketu@hel.fi`}</a>{` to learn about available implementations that follow HDS.`}</p>
    </Accordion>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      